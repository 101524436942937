const createSlug = (name) => {
	const lettersPl = {
		ą: 'a',
		ć: 'c',
		ę: 'e',
		ł: 'l',
		ń: 'n',
		ó: 'o',
		ś: 's',
		ź: 'z',
		ż: 'z',
	};
	const changeLetter = name.replace(/[ąćęłńóśźż]/g, (letter) => lettersPl[letter]);

	return changeLetter.toLowerCase().replace(/[\W_]+/g, '-');
};

export default createSlug;
