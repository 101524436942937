import fetchApi from '../helpers/fetch-api';

const LocationUser = () => {
	const defaultData = { city: 'warszawa', province: 'mazowieckie', provinceId: 7 };
	const locationAccepted = localStorage.getItem('locationAccept');

	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((position) => {
			const { latitude } = position.coords;
			const { longitude } = position.coords;
			// eslint-disable-next-line no-undef
			const latlng = new google.maps.LatLng(latitude, longitude);
			// eslint-disable-next-line no-undef
			const geocoder = new google.maps.Geocoder();

			geocoder.geocode({ location: latlng }, function (results, status) {
				if (status === 'OK') {
					const locationData = { city: '', province: '', provinceId: '' };
					/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
					for (let i = 0; i < results.length; i++) {
						const addressComponents = results[i].address_components;
						/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
						for (let j = 0; j < addressComponents.length; j++) {
							const { types } = addressComponents[j];

							if (types.indexOf('locality') !== -1) {
								locationData.city = addressComponents[j].long_name;
							}
							if (types.indexOf('administrative_area_level_1') !== -1) {
								const provinceName = addressComponents[j].long_name
									.toLowerCase()
									.replace(/województwo/g, '')
									.trim();
								locationData.province = provinceName;
							}
						}
					}

					if (locationData.province) {
						// eslint-disable-next-line no-undef
						fetchApi('GET', `${appVariables.apiUrl}/api/v1/provinces`).then((response) => {
							if (response.data) {
								if (response.data.length > 0) {
									response.data.forEach((provinceData) => {
										if (locationData.province === provinceData.name) {
											locationData.provinceId = provinceData.id;
										}
									});

									if (locationData)
										localStorage.setItem('locationData', JSON.stringify(locationData));
									else localStorage.setItem('locationData', JSON.stringify(defaultData));

									if (!locationAccepted) {
										localStorage.setItem('locationAccept', true);
										/* eslint-disable no-restricted-globals */
										location.reload();
									}
								}
							}
						});
					} else if (locationData) localStorage.setItem('locationData', JSON.stringify(locationData));
					else localStorage.setItem('locationData', JSON.stringify(defaultData));
				} else {
					console.log('Błąd geokodowania:', status);
					localStorage.setItem('locationData', JSON.stringify(defaultData));
				}
			});
		});
	}

	if (!localStorage.getItem('locationData')) localStorage.setItem('locationData', JSON.stringify(defaultData));
};

export default LocationUser;
