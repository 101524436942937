const renderStars = (rating) => {
	const rat = Math.floor(rating);
	let allStars = '';
	const starFull =
		'<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.112 15.943C3.726 16.141 3.288 15.794 3.366 15.351L4.196 10.621L0.672996 7.26501C0.343996 6.95101 0.514996 6.37701 0.955996 6.31501L5.854 5.61901L8.038 1.29201C8.235 0.902012 8.768 0.902012 8.965 1.29201L11.149 5.61901L16.047 6.31501C16.488 6.37701 16.659 6.95101 16.329 7.26501L12.807 10.621L13.637 15.351C13.715 15.794 13.277 16.141 12.891 15.943L8.5 13.687L4.111 15.943H4.112Z" fill="#11C0F2"/></svg>';
	const starEmpty =
		'<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.112 15.943C3.726 16.141 3.288 15.794 3.366 15.351L4.196 10.621L0.672996 7.26501C0.343996 6.95101 0.514996 6.37701 0.955996 6.31501L5.854 5.61901L8.038 1.29201C8.235 0.902012 8.768 0.902012 8.965 1.29201L11.149 5.61901L16.047 6.31501C16.488 6.37701 16.659 6.95101 16.329 7.26501L12.807 10.621L13.637 15.351C13.715 15.794 13.277 16.141 12.891 15.943L8.5 13.687L4.111 15.943H4.112Z" stroke="#11C0F2"/></svg>';
	const starHalf =
		'<svg width="17" height="17" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.03098 7.6785L11.307 1.188C11.3699 1.05743 11.4682 0.94715 11.5907 0.869714C11.7132 0.792279 11.855 0.7508 12 0.75C12.2745 0.75 12.549 0.8955 12.6975 1.188L15.9735 7.6785L23.3205 8.7225C23.5036 8.75129 23.6712 8.84237 23.795 8.98036C23.9188 9.11836 23.9912 9.29483 24 9.48C24.0105 9.60328 23.993 9.72733 23.9488 9.84291C23.9047 9.95848 23.835 10.0626 23.745 10.1475L18.4605 15.1815L19.7055 22.2765C19.8225 22.941 19.1655 23.4615 18.5865 23.1645L12 19.7805L5.41648 23.1645C5.34762 23.2003 5.27381 23.2255 5.19748 23.2395C4.68448 23.3295 4.19548 22.8585 4.29748 22.2765L5.54248 15.1815L0.259481 10.1475C0.17735 10.0698 0.112066 9.97604 0.0676841 9.87205C0.0233018 9.76806 0.000767948 9.65606 0.00148126 9.543C0.00155928 9.38315 0.0456733 9.22642 0.128981 9.09C0.188079 8.9911 0.26863 8.90674 0.364688 8.84313C0.460747 8.77953 0.569862 8.7383 0.683981 8.7225L8.03098 7.6785ZM12 18.0405C12.1211 18.04 12.2405 18.0688 12.348 18.1245L17.877 20.9655L16.836 15.03C16.8114 14.8935 16.8207 14.753 16.8631 14.621C16.9054 14.4889 16.9796 14.3692 17.079 14.2725L21.4395 10.1175L15.3615 9.2535C15.2356 9.23449 15.1163 9.18526 15.0136 9.11003C14.9109 9.03479 14.828 8.93579 14.772 8.8215L12.0015 3.3345L12 3.339V18.039V18.0405Z" fill="#11C0F2"></path></svg>';
	let count = 0;

	/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
	for (let i = 0; i <= rating - 1; i++) {
		count += 1;
		if (i < rat) allStars += starFull;
		else allStars += starEmpty;
	}

	if (rating - rat !== 0) {
		count += 1;
		allStars += starHalf;
	}

	/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
	for (let i = 0; i < 5 - count; i++) allStars += starEmpty;

	return allStars;
};

export default renderStars;
