const blockPartners = (Swiper, Autoplay) => {
	/* eslint-disable no-unused-vars */

	const blockMarker = '.js-swiper-partners';
	const blockContainer = document.querySelectorAll(blockMarker);
	if (!blockContainer) return false;

	blockContainer.forEach((block) => {
		// let swiper = '';

		const countDesktop = block.dataset.countdesktop ? block.dataset.countdesktop : 6;
		const countTablet = block.dataset.counttablet ? block.dataset.counttablet : 4;
		const countMobile = block.dataset.countmobile ? block.dataset.countmobile : 2;

		const swiperParams = {
			modules: [Autoplay],
			pagination: false,
			centeredSlides: false,
			navigation: false,
			autoplay: {
				delay: 3000,
			},
			slidesPerView: countMobile,
			spaceBetween: 15,
			loop: true,
			breakpoints: {
				768: {
					slidesPerView: countTablet,
				},
				992: {
					slidesPerView: countDesktop,
				},
			},
		};

		const swiper = new Swiper(block, swiperParams);

		// return swiper;
	});

	return true;
	/* eslint-enable no-unused-vars */
};

export default blockPartners;
