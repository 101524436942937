const collapse = () => {
	const collapses = document.querySelectorAll('.c-collapse');
	if (collapses.length > 0) {
		collapses.forEach((single) => {
			const header = single.querySelector('.c-collapse__header');
			const content = single.querySelector('.c-collapse__content');

			if (header && content) {
				header.addEventListener('click', (e) => {
					e.preventDefault();
					collapses.forEach((singleHide) => {
						singleHide.classList.remove('c-collapse--show');
					});
					single.classList.toggle('c-collapse--show');
				});
			}
		});
	}
};
export default collapse;
