import fetchApi from '../helpers/fetch-api';
import formatedData from '../helpers/formatedData';
import renderStars from '../helpers/renderStars';

const blockLatestReviews = () => {
	const wrapper = document.querySelector('.js-latest-reviews-faciality-result');

	const renderBoxReview = (review) => {
		let reviewBox = '';

		const { createdAt, rating, content } = review;
		const newFormatedDate = formatedData(createdAt);
		let displayName = 'Pacjent';
		let titleReview = '';
		// eslint-disable-next-line no-undef
		let userImg = `${appVariables.assetsUrl}user-review.png`;

		if ('name' in review) {
			if (review.name) displayName = review.name;
		}

		if ('facility' in review) {
			if ('name' in review.facility) titleReview = review.facility.name.toLowerCase();
		} else if ('doctor' in review) {
			if ('firstName' in review.doctor && 'lastName' in review.doctor)
				titleReview = `lek. ${review.doctor.firstName} ${review.doctor.lastName}`;
		}

		if ('addedByUser' in review) {
			if (review.addedByUser) {
				if ('name' in review.addedByUser) displayName = review.addedByUser.name;

				if ('avatar' in review.addedByUser) {
					if (review.addedByUser.avatar) userImg = review.addedByUser.avatar.accessUrl;
				}
			}
		}

		reviewBox += "<div class='col-12 b-latest-reviews__col-rev'>";
		reviewBox += "<div class='c-review-long'>";
		reviewBox += "<div class='c-review-long__img-wrapper'>";
		reviewBox += `<img class='c-review-long__img' src="${userImg}" alt="${displayName}">`;
		reviewBox += '</div>';
		reviewBox += "<div class='c-review-long__wrapper'>";
		reviewBox += "<div class='c-review-long__header'>";
		reviewBox += "<div class='c-review-long__header-left'>";
		reviewBox += `<span class='c-review-long__header-name'>${displayName}</span>`;
		reviewBox += `<span class='c-review-long__header-date'>${newFormatedDate}</span>`;
		reviewBox += '</div>';
		reviewBox += "<div class='c-review-long__header-right'>";
		reviewBox += `<div class='c-rating-value c-rating-value--end'>`;
		reviewBox += `<div class='c-rating-value__stars'>${renderStars(parseFloat(rating))}</div>`;
		reviewBox += `<div class='c-rating-value__rating'>${parseFloat(rating).toFixed(2)} / 5</div>`;
		reviewBox += '</div>';
		reviewBox += '</div>';
		reviewBox += '</div>';
		reviewBox += "<div class='c-review-long__content'>";
		reviewBox += `<p class='c-review-long__text'>${content || ''}</p>`;
		reviewBox += '</div>';
		reviewBox += "<div class='c-review-long__footer'>";
		reviewBox += `<div class='c-review-long__text c-review-long__text--footer'>Opinia dotyczy: <strong>${titleReview}</strong></div>`;
		reviewBox += '</div>';
		reviewBox += '</div>';
		reviewBox += '</div>';
		reviewBox += '</div>';

		return reviewBox;
	};

	const fetchDataReviewsFacility = () => {
		// eslint-disable-next-line no-undef
		let reviewsBoxes = '';
		const limitReviews = wrapper.getAttribute('data-reviewsLimit') ? wrapper.getAttribute('data-reviewsLimit') : 3;
		const parametersApi = `sortOrder=DESC&sortBy=createdAt&status=ACCEPTED&limit=${limitReviews}`;

		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/reviews?${parametersApi}`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((review) => {
						reviewsBoxes += renderBoxReview(review);
					});

					if (reviewsBoxes.length > 0) wrapper.innerHTML = reviewsBoxes;
				}
			}
		});
	};

	if (wrapper) fetchDataReviewsFacility();
};

export default blockLatestReviews;
