const fetchApi = async (type = '', url = '') => {
	const response = await fetch(url, {
		method: type,
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
	});
	return response.json();
};
export default fetchApi;
