import collapse from '../helpers/collapse';
import fetchApi from '../helpers/fetch-api';
import cPopup from '../popup';

const svgGreen =
	'<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" rx="15" fill="#249B4D"/><path d="M7 15.6111L11.9231 20.5L23 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const svgRed =
	'<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" rx="15" fill="#ED2369"/><path d="M9 9L21 21M21 9L9 21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const uid = () => {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const getSubstance = (substanceId, countryId, cancerId) => {
	// eslint-disable-next-line no-undef
	const url = new URL(`${appVariables.apiUrl}/api/v1/substance/active-substance-short`);
	url.search = new URLSearchParams({ substanceId, countryId, cancerId }).toString();

	return fetchApi('GET', url)
		.then((response) => {
			return response;
		})
		.catch(() => {
			// error
			// console.log('getSubstance error:', error);
		});
};

const testTriggerInit = () => {
	const testTriggers = document.querySelectorAll('.test-trigger');
	const popupResults = document.querySelector('.popup-results');
	if (testTriggers.length > 0) {
		testTriggers.forEach((el) => {
			el.addEventListener('click', (e) => {
				let html = '';
				const id = e.currentTarget.getAttribute('data-id');
				fetchApi(
					'GET',
					// eslint-disable-next-line no-undef
					`${appVariables.apiUrl}/api/v1/pages/${id}`,
				)
					.then((response) => {
						const { data } = response;

						html += '<div class="c-popup-search__content">';
						html += '<div class="c-popup-search__left">';

						if (data.title) {
							html += `<div class="c-popup-search__title">${data.title}</div>`;
						}
						if (data.lead) {
							html += `<div class="c-popup-search__text">${data.lead}</div>`;
						}
						if (data.lead) {
							html += `<a href="/app/details/${id}" class="c-btn c-btn--accent c-popup-search__link">ZOBACZ WIĘCEJ SZCZEGÓŁÓW</a>`;
						}

						html += '</div>';
						html += '<div class="c-popup-search__right">';
						html += `<div class="c-popup-search__reasons">Możliwe przyczyny</div>`;
						if (data.childPage) {
							data.childPage.forEach((child) => {
								html += `<div class="c-popup-search__collapse c-collapse">`;

								html += '<div class="c-popup-search__collapse-title c-collapse__header">';
								html += child.title;
								html +=
									'<div><svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="#11C0F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>';
								html += '</div>';

								html += `<div class="c-popup-search__collapse-content c-collapse__content">`;
								child.childPage.forEach((subChild) => {
									html += `<div class="c-popup-search__treatment-title">Niezbędne badanie:</div>`;
									html += `<div class="c-popup-search__treatment-sub-title">${subChild.title}</div>`;
									html += '<div class="c-popup-search__treatment-title">Możliwość leczenia: </div>';
									subChild.activeSubstance.forEach((substance) => {
										const uniqId = uid();
										html += `<div class="c-popup-search__treatment-sub-title">${substance.name}</div>`;
										html += `<div class="c-popup-search__treatment-content ${uniqId}"></div>`;

										const substanceResponse = getSubstance(substance.id, 1, subChild.cancer[0].id);

										substanceResponse.then((res) => {
											const isRecommended = res.data[0].isRecommendationActiveSubstanceInCancer;
											const { value } = res.data[0].refundationActiveSubstanceCancerMetaKey;
											const substanceId = res.data[0].refundationActiveSubstanceCancerMetaKey.id;

											const checkIsFail =
												substanceId === 101 ||
												substanceId === 102 ||
												substanceId === 106 ||
												substanceId === 107;

											let buildSubstanceHtml = `<div class="c-popup-search__refunds">`;

											buildSubstanceHtml += `<div class="c-popup-search__refund c-popup-search__refund--first ${
												checkIsFail
													? 'c-popup-search__refund--fail'
													: 'c-popup-search__refund--ok'
											}">`;

											buildSubstanceHtml += (checkIsFail ? svgRed : svgGreen) + value;

											buildSubstanceHtml += '</div>';

											if (isRecommended) {
												buildSubstanceHtml += `<div class="c-popup-search__refund c-popup-search__refund--ok">`;

												buildSubstanceHtml += `${svgGreen}ESMO`;

												buildSubstanceHtml += '</div>';
											}
											buildSubstanceHtml += '</div>';

											buildSubstanceHtml += `<a href="https://oncoindex.org/pl/polska/substancja-czynna/${res.data[0].activeSubstance.id}/${res.data[0].activeSubstance.slug}" target="_blank" class="c-popup-search__more">WIĘCEJ O LEKU W SERWISIE ONKOINDEX.ORG</a>`;
											document.querySelector(`.${uniqId}`).innerHTML = buildSubstanceHtml;
										});
									});
								});
								html += `</div>`;
								html += `</div>`;
							});
						}
						html += '</div>';
						html += '</div>';

						popupResults.innerHTML = html;
						collapse();
					})
					.catch(() => {
						// error
						// console.log('Error:', error);
					});
			});
		});
	}
};

const blockSearchOnkosnajper = () => {
	let resultsHtml = '';
	let timeout = null;

	const searchOnkosnajperForm = document.querySelector('.search-onkosnajper-form');
	const searchResults = document.getElementById('search-results');

	const bSearchSearch = document.querySelector('.b-search__search');

	const cSearchRing = document.querySelector('.c-search-ring');

	if (bSearchSearch) {
		bSearchSearch.addEventListener('input', (e) => {
			if (cSearchRing) {
				cSearchRing.classList.remove('c-search-ring--hidden');
			}
			e.currentTarget.closest('form').dispatchEvent(new CustomEvent('submit', { cancelable: true }));
		});
	}

	if (searchOnkosnajperForm) {
		searchOnkosnajperForm.addEventListener('submit', (e) => {
			e.preventDefault();

			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => {
				fetchApi(
					'GET',
					// eslint-disable-next-line no-undef
					`${appVariables.apiUrl}/api/v1/pages/findByNameAndCategory?titleLike=${e.target.search.value}&categoryIds=${e.target.ids.value}`,
				)
					.then((response) => {
						if (response.length > 0) {
							resultsHtml = '';
							searchResults.classList.add('b-search__custom-select--show');
							resultsHtml += `<div class="b-search__custom-select-option b-search__custom-select-option--bold">Strony</div>`;
							response.forEach((single) => {
								// eslint-disable-next-line no-undef
								resultsHtml += `<div class="b-search__custom-select-option popup-trigger test-trigger" data-target="popup-search" data-id="${single.id}">${single.title}</div>`;
							});
							searchResults.innerHTML = resultsHtml;
							cPopup();
						} else {
							searchResults.classList.remove('b-search__custom-select--show');
							searchResults.innerHTML = '';
						}
						if (cSearchRing) {
							cSearchRing.classList.add('c-search-ring--hidden');
						}
						testTriggerInit();
					})
					.catch(() => {
						// error
						// console.log('Error:', error);
					});
			}, 800);
		});
	}
};

export default blockSearchOnkosnajper;
