const blockCollapse = () => {
	const accordions = document.querySelectorAll('.accordion-js');

	if (accordions) {
		accordions.forEach((container) => {
			const btns = container.querySelectorAll('.accordion-btn-js');
			const accordDesc = container.querySelectorAll('.c-accordion__description');

			btns.forEach((btn) => {
				btn.addEventListener('click', (e) => {
					const classTargetItem = `.${e.currentTarget.getAttribute('data-target')}`;
					const targetItem = container.querySelector(classTargetItem);

					if (!targetItem.classList.contains('is-active')) {
						accordDesc.forEach((it) => it.classList.remove('is-active'));
						btns.forEach((it) => it.classList.remove('active'));

						targetItem.classList.add('is-active');
						e.currentTarget.classList.add('active');
						targetItem.style.height = 'auto';
						const height = `${targetItem.clientHeight}px`;
						targetItem.style.height = '0px';
						setTimeout(() => {
							targetItem.style.height = height;
						}, 0);
					} else {
						targetItem.style.height = '0px';
						e.currentTarget.classList.remove('active');
						targetItem.addEventListener(
							'transitionend',
							() => {
								targetItem.classList.remove('is-active');
							},
							{ once: true },
						);
					}
				});

				if (btn.classList.contains('active-start')) {
					setTimeout(() => {
						btn.click();
					}, 500);
				}
			});
		});
	}
};

export default blockCollapse;
