const mainNav = () => {
	// Mobile menu
	const navMobileBtn = document.getElementById('js-main-nav-mobile');
	if (navMobileBtn) {
		navMobileBtn.onclick = (event) => {
			event.currentTarget.classList.toggle('active');
			event.currentTarget.previousElementSibling.classList.toggle('d-none');
		};

		const createArrowElement = () => {
			const arrowSVG = `
			  <svg width='15' height='9' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M9.62506 10.5001C9.40128 10.5001 9.17728 10.4145 9.00643 10.2437L0.25643 1.49368C-0.0854766 1.15177 -0.0854766 0.598118 0.25643 0.25643C0.598336 -0.0852579 1.15199 -0.0854767 1.49368 0.25643L9.62506 8.38782L17.7564 0.25643C18.0983 -0.0854767 18.652 -0.0854767 18.9937 0.25643C19.3354 0.598337 19.3356 1.15199 18.9937 1.49368L10.2437 10.2437C10.0728 10.4145 9.84884 10.5001 9.62506 10.5001Z' fill='#003b50'/>
			  </svg>
			`;
			const arrowSpan = document.createElement('span');
			arrowSpan.classList.add('arrow-show-menu', 'main-arrow');
			arrowSpan.innerHTML = arrowSVG;
			return arrowSpan;
		};

		document.querySelectorAll('.menu-item-has-children').forEach((menuItem) => {
			const arrowElement = createArrowElement();
			const firstChild = menuItem.firstElementChild;
			menuItem.insertBefore(arrowElement, firstChild.nextSibling);
		});

		const toggleSubmenu = function () {
			this.classList.toggle('show-sub');
		};

		document.querySelectorAll('.menu-item-has-children').forEach((arrow) => {
			arrow.addEventListener('click', toggleSubmenu);
		});
	}
};

export default mainNav;
