import fetchApi from '../helpers/fetch-api';
import eventFire from '../helpers/event';

/* globals appVariables blockSearchOnkoindexData */

const setSearchValue = (popupSelector, searchInput, select = true) => {
	const listValues = popupSelector.querySelectorAll('[data-value]');
	const popupClose = popupSelector.querySelector('.popup-trigger-close');
	const input = searchInput;
	listValues.forEach((element) => {
		const listValue = element;
		listValue.onclick = () => {
			const { value, id, type } = listValue.dataset;
			input.value = value;
			input.dataset.id = id;
			input.dataset.type = type;
			input.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

			if (select) {
				const customListClass = 'b-search__custom-select--show';
				const customList = input.closest('[data-search-onkoindex]');
				customList.querySelector(`.${customListClass}`).classList.remove(customListClass);
			}

			if (!popupClose) return;
			popupClose.click();
		};
	});
};

const changePage = (popupSelector, searchInput) => {
	const pages = popupSelector.querySelectorAll('[data-pagination-item]');
	pages.forEach((element) => {
		const page = element;
		page.onclick = () => {
			const { offset, type } = page.dataset;
			// eslint-disable-next-line no-use-before-define
			renderPopupList(popupSelector, searchInput, type, undefined, offset);
		};
	});
};

const getList = (type, limit = 30, offset = 0, word = '') => {
	return fetchApi(
		'GET',
		`${appVariables.apiUrl}/api/v1/${type}?sortOrder=ASC&orderBy=name&limit=${Number(limit)}&offset=${Number(
			offset,
		)}&nameLike=${word}`,
	);
};

const renderPopupList = (
	popupSelector,
	searchInput,
	dataType = blockSearchOnkoindexData.cancer.slug,
	dataLimit = 30,
	dataOffset = 0,
) => {
	const limit = dataLimit;
	const offset = dataOffset;
	const type = dataType;
	const popup = popupSelector;
	const popupResults = popup.querySelector('[data-source-results]');
	const popupPagination = popup.querySelector('[data-source-pagination]');
	const icon =
		'<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1L7.5 7L1.5 13" stroke="#003b50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>';

	const list = getList(type, limit, offset);

	list.then((response) => {
		const { data, meta } = response;
		const pages = Math.floor(meta.totalResults / limit);
		let html = '';

		data.forEach((el) => {
			const cancer = el;
			html += `<li class="d-flex align-items-center justify-content-between" data-type="${type}" data-id="${
				cancer.id
			}" data-value="${cancer.name}">${cancer.name + icon}</li>`;
		});

		popupResults.innerHTML = `<ul>${html}</ul>`;

		html = '';
		for (let page = 0; page <= pages; page += 1) {
			const active = (page === 0 && offset === 0) || offset / limit === page ? 'active' : '';

			html += `<li class="c-pagination__item ${active}" data-pagination-item data-type="${type}" data-offset="${
				page * limit
			}">${page + 1}</li>`;
		}

		popupPagination.innerHTML = `<ul class="c-pagination" data-pagination>${html}</ul>`;

		setSearchValue(popupSelector, searchInput, false);
		changePage(popupSelector, searchInput);
	});
};

const renderHints = (searchInput, searchResults) => {
	const input = searchInput;
	const results = searchResults;
	const limit = 10;
	const loaderClass = 'c-search-ring--hidden';
	const { cancer } = blockSearchOnkoindexData;
	const { substance } = blockSearchOnkoindexData;

	const template = {
		item: (data, slug) => {
			return `<div class="b-search__custom-select-option" data-value="${data.name}" data-id="${data.id}" data-type="${slug}">${data.name}</div>`;
		},
		heading: (data) => {
			return `<div class="b-search__custom-select-option b-search__custom-select-option--bold">${data.label}</div>`;
		},
	};

	const cancerResults = results.querySelector(`[data-results-type="${cancer.slug}"]`);
	const substanceResults = results.querySelector(`[data-results-type="${substance.slug}"]`);
	const loader = searchInput.nextElementSibling;

	const active = {
		class: 'b-search__custom-select--show',
		add: (length) => {
			results.classList.add(active.class);

			if (
				(cancerResults.childNodes.length === 0 && substanceResults.childNodes.length === 0) ||
				typeof length === 'undefined'
			) {
				results.classList.remove(active.class);
			}
		},
	};

	input.oninput = () => {
		active.add();

		if (input.value.length <= 2) {
			cancerResults.innerHTML = '';
			substanceResults.innerHTML = '';
			if (loader) {
				loader.classList.add(loaderClass);
			}
			return;
		}

		if (loader) {
			loader.classList.remove(loaderClass);
		}

		const hintsCancer = getList(cancer.slug, limit, undefined, input.value);
		const hintsSubstance = getList(substance.slug, limit, undefined, input.value);

		const lists = {
			cancer: { data: hintsCancer, slug: cancer.slug, results: cancerResults, title: cancer },
			substance: { data: hintsSubstance, slug: substance.slug, results: substanceResults, title: substance },
		};

		Object.values(lists).forEach((item) => {
			const list = item;
			let html = '';
			list.data.then((response) => {
				const { data } = response;

				if (data.length > 0) {
					html = template.heading(list.title);
					data.forEach((el) => {
						const hint = el;
						html += template.item(hint, list.slug);
					});
				} else {
					html = '';
				}
				list.results.innerHTML = html;
				setSearchValue(results, searchInput, undefined);
				active.add(input.value.length);

				if (loader) {
					loader.classList.add(loaderClass);
				}
			});
		});
	};
};

const changeTab = (popupSelector, searchInput) => {
	const tabs = popupSelector.querySelectorAll('[data-source-tab]');
	const input = searchInput;
	tabs.forEach((el) => {
		const tab = el;
		tab.onclick = () => {
			const { sourceTab } = tab.dataset;
			const active = popupSelector.querySelector('[data-source-tab].active');

			if (tab.classList.contains('active')) return;

			active.classList.remove('active');
			tab.classList.add('active');
			renderPopupList(popupSelector, input, sourceTab);
		};
	});
};

const updateSession = (selector, key, data) => {
	const name = key;
	const countries = selector.querySelectorAll(data);

	const session = {
		set: () => {
			const current = selector.querySelector(`${data}.active`);
			sessionStorage.setItem(name, current.dataset.countryId);
		},
	};

	session.set();

	const options = {
		attributes: true,
		childList: false,
		characterData: false,
		attributeOldValue: false,
		attributeFilter: ['class'],
		subtree: false,
	};

	countries.forEach((el) => {
		const country = el;

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					session.set();
				}
			});
		});

		observer.observe(country, options);
	});
};

const getCountries = (selector) => {
	let html = '';
	const divCurrnet = document.querySelector('[data-search-onkoindex-country]');
	const divList = selector;
	let countries = fetchApi('GET', `${appVariables.apiUrl}/api/v1/countries`);
	countries.then((response) => {
		const { data } = response;
		countries = data.filter((country) => {
			return country.active === true;
		});
		countries.forEach((country, i) => {
			let itemClass = '';
			const selectItemClass = 'c-select-list__item';

			if (i === 0) {
				itemClass = `active ${selectItemClass}`;
				divCurrnet.innerHTML = country.name;
			} else {
				itemClass = selectItemClass;
			}

			const template = `<div data-select-list-item class="${itemClass}" data-country-id="${country.id}">${country.name}</div>`;
			html += template;
		});
		divList.innerHTML = `<div class="c-select-list__items">${html}</div>`;

		eventFire('select');
		updateSession(selector, 'countryId', '[data-country-id]');
	});
};

const onSubmit = (searchForm, searchInput) => {
	const form = searchForm;
	const input = searchInput;
	form.onsubmit = (e) => {
		e.preventDefault();
		let { id, type } = input.dataset;
		const first = searchForm.querySelector('[data-value]');

		if (first) {
			id = first.dataset.id;
			type = first.dataset.type;
		}

		if (!id && !type) {
			input.focus();
			return;
		}

		form.action = `${blockSearchOnkoindexData.url}?${type.replace('cancers', 'cancer')}=${id}`;
		form.submit();
	};
};

const blockSearchOnkoIndex = () => {
	const searchForm = document.querySelector('[data-search-onkoindex]');
	if (!searchForm) return;
	const countriesList = document.querySelector('[data-search-onkoindex-countries]');
	const searchPopup = document.querySelector('[data-source="popup-search-onkoindex"]');
	const searchInput = searchForm.querySelector('#search-onkoindex');
	const searchResults = searchForm.querySelector('#search-onkoindex-results');

	if (countriesList) getCountries(countriesList);

	if (searchPopup) renderPopupList(searchPopup, searchInput);
	changeTab(searchPopup, searchInput);

	if (searchForm) renderHints(searchInput, searchResults);
	onSubmit(searchForm, searchInput);
};
export default blockSearchOnkoIndex;
