const blockSliderPagination = (Swiper, Autoplay, Navigation, Pagination) => {
	const block = '.js-swiper-slider-pagination';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';
	const paginationEl = blockContainer.querySelector('.swiper-pagination');
	const swiperParams = {
		modules: [Autoplay, Navigation, Pagination],
		pagination: {
			el: paginationEl,
		},
		centeredSlides: false,
		navigation: false,
		autoplay: {
			delay: 3000,
		},
		slidesPerView: 1,
		spaceBetween: 0,
		loop: false,
		// breakpoints: {
		// 	768: {
		// 		slidesPerView: 3,
		// 		spaceBetween: 24,
		// 	},
		// },
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockSliderPagination;
