import fetchApi from '../helpers/fetch-api';

const blockSearchDoctors = () => {
	/**
	 *
	 * Przekierowanie w wyszukiwarce:
	 * /app/results/doctors ~ facialities
	 *
	 */

	const locationData = JSON.parse(localStorage.getItem('locationData'));
	const specialization = document.getElementById('specialization');

	if (specialization) {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/doctor-specialization`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((specializationData) => {
						const option = document.createElement('option');
						option.text = specializationData.name;
						option.value = specializationData.id;
						specialization.appendChild(option);
					});
				}
			}
		});
	}

	const province = document.querySelector('#search-doctors-faciality-spec #province');

	if (province) {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/provinces`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((provinceData) => {
						let optExist = false;
						const option = document.createElement('option');
						option.text = provinceData.name;
						option.value = provinceData.id;
						if (locationData.province === provinceData.name) option.selected = true;

						if (province.options) {
							for (let i = 0; i < province.options.length; i += 1) {
								if (province.options[i].text === option.text) {
									optExist = true;
								}
							}
						}

						// console.log(province.options);

						if (!optExist) {
							province.appendChild(option);
						}
					});
				}
			}
		});
	}

	// detects changes
	const radioInputs = document.querySelectorAll('.js-radio-input');
	const form = document.getElementById('form-doctor-faciality');

	if (radioInputs) {
		radioInputs.forEach((input) => {
			const val = input.value;
			const dataMarker2 = input.getAttribute('data-marker');
			// init action url
			if (input.checked && form) {
				const actionUrl = form.getAttribute('data-baseUrl');
				if (dataMarker2 === 'doctors') {
					if (actionUrl) form.setAttribute('action', `${actionUrl + val}`);
				} else if (dataMarker2 === 'facilities') {
					if (actionUrl) form.setAttribute('action', `${actionUrl + val}`);
				}
			}

			input.addEventListener('change', (e) => {
				const { value } = e.currentTarget;
				const allInputsChange = document.querySelectorAll('.js-modify-input');
				const dataMarker = e.currentTarget.getAttribute('data-marker');
				let activeClass = '';
				let actionUrl = '';

				if (form) actionUrl = form.getAttribute('data-baseUrl');

				if (dataMarker === 'facilities') {
					activeClass = 'js-institute';
					if (actionUrl) form.setAttribute('action', `${actionUrl + value}`);
				} else if (dataMarker === 'doctors') {
					activeClass = 'js-doctor';
					if (actionUrl) form.setAttribute('action', `${actionUrl + value}`);
				}

				if (allInputsChange) {
					allInputsChange.forEach((el) => {
						if (el.classList.contains(activeClass)) {
							el.classList.remove('hidden');
							if (el.classList.contains('js-doctor') && activeClass === 'js-institute') {
								el.classList.replace('col-lg-3', 'col-lg-4');
							} else if (el.classList.contains('js-institute') && activeClass === 'js-doctor') {
								el.classList.replace('col-lg-4', 'col-lg-3');
							}
						} else {
							el.classList.add('hidden');
						}
					});
				}
			});
		});
	}

	// change ulrs
	if (form) {
		form.addEventListener('submit', (e) => {
			e.preventDefault();
			const formUrl = form.getAttribute('action');
			const formData = new FormData(form);
			const data = {};

			/* eslint-disable */
			for (const [key, value] of formData.entries()) {
				if (value.trim() && key !== 'searchBy') data[key] = value.trim();
			}
            /* eslint-enable */

			const params = new URLSearchParams(data);

			if (formUrl && params) {
				if ('searchBy' in params) params.delete('searchBy');
				const actionFilteredUrl = `${formUrl}/?${params.toString()}`;
				window.location.href = actionFilteredUrl;
			}
		});
	}
};

export default blockSearchDoctors;
