import fetchApi from '../helpers/fetch-api';

const blockMapPlaces = () => {
	// eslint-disable no-undef
	const mapContent = document.getElementById('map-place-custom');
	const locationData = JSON.parse(localStorage.getItem('locationData'));
	let map = '';
	const cityDefault = locationData ? locationData.city : 'warszawa';
	/* eslint-disable no-shadow */
	const createMarker = (markerData, map) => {
		const { name, street, city } = markerData.data;

		const medicalIcon = {
			url: 'http://maps.google.com/mapfiles/kml/pal3/icon21.png',
			// eslint-disable-next-line no-undef
			scaledSize: new google.maps.Size(32, 32),
		};

		// eslint-disable-next-line no-undef
		const marker = new google.maps.Marker({
			map,
			position: markerData.location,
			icon: medicalIcon,
		});

		// eslint-disable-next-line no-undef
		const infowindow = new google.maps.InfoWindow({
			content: `<div style="max-width:300px;"><strong style="display:block;margin-bottom:5px;">${name}</strong>${street}<br/>${city}</div>`,
		});

		marker.addListener('click', () => infowindow.open(map, marker));
	};

	const detectChangePlace = (map3) => {
		const provinceInput = document.querySelector('.js-custom-select #province');

		if (provinceInput) {
			provinceInput.addEventListener('change', () => {
				const { selectedIndex } = provinceInput;
				const selectedOptionName = provinceInput.options[selectedIndex].text;

				if (selectedOptionName) {
					// eslint-disable-next-line no-undef
					const geocoder = new google.maps.Geocoder();
					geocoder.geocode({ address: selectedOptionName }, function (results, status) {
						if (status === 'OK') {
							const { location } = results[0].geometry;

							if (location) {
								map3.setCenter(location);
								map3.setZoom(10);
							}
						}
					});
				}
			});
		}
	};

	const initMap = (pins) => {
		// eslint-disable-next-line no-undef
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: cityDefault }, function (results, status) {
			if (status === 'OK') {
				const { location } = results[0].geometry;
				// eslint-disable-next-line no-undef
				map = new google.maps.Map(mapContent, {
					center: location,
					zoom: 13,
					styles: [
						{
							featureType: 'poi',
							stylers: [{ visibility: 'off' }],
						},
						{
							featureType: 'transit',
							stylers: [{ visibility: 'off' }],
						},
					],
				});

				if (pins) pins.forEach((pin) => createMarker(pin, map));

				detectChangePlace(map);
			} else {
				console.log('Problem z wyswietleniem mapy');
			}
		});
	};

	const fetchFaciality = () => {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/facilities?sortOrder=DESC&limit=20000`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					const dataPins = [];
					response.data.forEach((facialitiesData) => {
						const { latitude, longitude, name, street, zipCode, city } = facialitiesData;

						if ('latitude' in facialitiesData && 'latitude' in facialitiesData) {
							dataPins.push({
								location: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
								data: { name, city: `${city.name} ${zipCode}`, street },
							});
						}
					});

					if (dataPins.length > 0) initMap(dataPins);
				}
			}
		});
	};

	if (mapContent) fetchFaciality();
	// eslint-enable no-undef
};

export default blockMapPlaces;
