import fetchApi from '../helpers/fetch-api';
// import slugify from '../../../../../../../../node_modules/slugify';

const fetchCities = (chosenValue, cities) => {
	// eslint-disable-next-line no-undef
	fetchApi('GET', `${appVariables.apiUrl}/api/v1/cities?limit=500&provinceId=${chosenValue}`).then((response) => {
		if (response.data) {
			if (response.data.length > 0) {
				response.data.forEach((provinceData) => {
					const option = document.createElement('option');
					option.text = provinceData.name;
					option.value = provinceData.id;
					cities.appendChild(option);
				});
			}
		}
	});
};
const blockSearch = () => {
	/**
	 *
	 * Przekierowanie w wyszukiwarce:
	 * /app/results/service/serviceId/queue/queueId/province/provinceId/city/cityId
	 * Czyli kierujemy na:
	 * /app/results/rezonans/1/normalna/1/dolnoslaskie/1/warszawa/10/
	 *
	 */
	const service = document.getElementById('service');

	if (service) {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/services?active=1`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((serviceData) => {
						const option = document.createElement('option');
						option.text = serviceData.name;
						option.value = serviceData.id;
						service.appendChild(option);
					});
				}
			}
		});
	}
	const asap = document.getElementById('asap');

	if (asap) {
		asap.addEventListener('change', (e) => {
			const asap2 = document.getElementById('asap-2');
			if (e.currentTarget.checked) {
				e.currentTarget.value = 2;
				asap2.disabled = true;
			} else {
				asap2.disabled = false;
			}
		});
	}

	const province = document.getElementById('province');

	if (province) {
		// eslint-disable-next-line no-undef
		fetchApi('GET', `${appVariables.apiUrl}/api/v1/provinces`).then((response) => {
			if (response.data) {
				if (response.data.length > 0) {
					response.data.forEach((provinceData) => {
						const option = document.createElement('option');
						option.text = provinceData.name;
						option.value = provinceData.id;
						province.appendChild(option);
					});
				}
			}
		});
	}

	const cities = document.getElementById('cities');

	if (cities && province) {
		// if (province.options.length > 0) {
		// 	fetchCities(province.options[0], cities); // inicjalizacja miast z pierwszego województwa
		// }
		province.addEventListener('change', (e) => {
			const currentOptions = cities.querySelectorAll('option');
			if (currentOptions.length > 0) {
				currentOptions.forEach((option) => {
					option.remove();
				});
				const option = document.createElement('option');
				option.text = 'wszystkie';
				option.value = '';
				cities.appendChild(option);
			}
			const chosenValue = e.currentTarget.options[e.currentTarget.selectedIndex].value;
			fetchCities(chosenValue, cities);
		});
	}
};

export default blockSearch;
