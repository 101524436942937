const removeClass = (elements, className) => {
	elements.forEach((el) => {
		el.classList.remove(className);
	});
};

const selectList = () => {
	const lists = document.querySelectorAll('[data-select-list]');
	lists.forEach((element) => {
		const list = element;
		const item = list.querySelector('[data-select-list-current]');
		const currentValue = list.querySelector('[data-select-list-current-value]');
		const itemsDiv = list.querySelector('[data-select-list-items]');
		const items = list.querySelectorAll('[data-select-list-item]');

		item.onclick = () => {
			item.classList.toggle('active');
			itemsDiv.classList.toggle('active');
		};

		items.forEach((el) => {
			const value = el;
			value.onclick = () => {
				removeClass(items, 'active');
				value.classList.add('active');
				item.click();
				currentValue.innerHTML = value.textContent;
			};
		});
	});
};

document.addEventListener('select', selectList);

export default selectList;
