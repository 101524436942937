const blockSlider = (Swiper, Autoplay) => {
	const block = '.js-swiper-slider';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		centeredSlides: false,
		navigation: false,
		autoplay: {
			delay: 3000,
		},
		slidesPerView: 1,
		spaceBetween: 0,
		loop: false,
		// breakpoints: {
		// 	768: {
		// 		slidesPerView: 3,
		// 		spaceBetween: 24,
		// 	},
		// },
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockSlider;
