const cPopup = () => {
	const popupTriggers = document.querySelectorAll('.popup-trigger');
	if (popupTriggers.length > 0) {
		popupTriggers.forEach((popupTrigger) => {
			popupTrigger.addEventListener('click', (e) => {
				e.preventDefault();
				const findPopup = document.querySelector(
					`[data-source="${e.currentTarget.getAttribute('data-target')}"]`,
				);
				if (findPopup) {
					findPopup.classList.add('c-popup--show');
				}
			});
		});
	}

	const popups = document.querySelectorAll('.c-popup');

	if (popups.length > 0) {
		popups.forEach((popup) => {
			const closes = popup.querySelectorAll('.popup-trigger-close');
			if (closes.length > 0) {
				closes.forEach((close) => {
					close.addEventListener('click', (e) => {
						e.preventDefault();
						const currentPopup = popup.closest('.c-popup');
						currentPopup.classList.remove('c-popup--show');
						if (currentPopup.getAttribute('data-source') === 'shop-checkout-login') {
							const guestInput = document.querySelector('#guest-user');
							const loginUser = document.querySelector('#login-user');
							const billingEmail = document.querySelector('.c-shop-field--billing-email');
							if (guestInput) guestInput.checked = true;
							if (loginUser) loginUser.checked = false;
							if (billingEmail) billingEmail.style.display = 'block';
						}
					});
				});
			}
			const overlay = popup.querySelector('.c-popup__overlay');
			if (overlay) {
				overlay.addEventListener('click', (e) => {
					e.preventDefault();
					const currentPopup = popup.closest('.c-popup');
					currentPopup.classList.remove('c-popup--show');
				});
			}
		});
	}
};

export default cPopup;
