/*
  Project: Onkoskaner
  Author: WebCrafters Studio
 */
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import blockSlider from './modules/blocks/block-slider';
import blockPartners from './modules/blocks/block-partners';
import blockPosts from './modules/blocks/block-posts';
import blockStats from './modules/blocks/block-stats';
// import './modules/select';
import mainNav from './modules/main-nav';
import blockSearch from './modules/blocks/block-search';
import blockSearchOnkosnajper from './modules/blocks/block-search-onkosnajper';
import cPopup from './modules/popup';
import collapse from './modules/helpers/collapse';
import blockSearchOnkoIndex from './modules/blocks/block-search-onkoindex';
import selectList from './modules/helpers/select-list';
import blockSearchDoctors from './modules/blocks/block-search-doctors';
import blockfacilitiesNearby from './modules/blocks/block-facilities-nearby';
import blockDoctorsNearby from './modules/blocks/block-doctors-nearby';
import blockLatestReviews from './modules/blocks/block-latest-reviews';
import blockMapPlaces from './modules/blocks/block-map-places';
import LocationUser from './modules/blocks/location-user';
import anchormenu from './modules/blocks/anchor-menu';
import blockCollapse from './modules/blocks/block-collapse';
import blockSliderPagination from './modules/blocks/block-slider-pagination';

document.addEventListener('DOMContentLoaded', () => {
	// checked if is block for location
	const mapBlock = document.getElementById('map-places-spec-js');
	const searchFaciality = document.getElementById('search-doctors-faciality-spec');
	const nearbyFaciality = document.getElementById('faciality-nerby-js-c');

	if (mapBlock || searchFaciality || nearbyFaciality) LocationUser();

	blockSlider(Swiper, Autoplay);
	blockPartners(Swiper, Autoplay);
	blockPosts(Swiper, Autoplay);
	blockStats();
	blockSearch();
	mainNav();
	blockSearchOnkosnajper();
	blockSearchOnkoIndex();
	cPopup();
	collapse();
	selectList();
	blockfacilitiesNearby();
	blockDoctorsNearby();
	blockSearchDoctors();
	blockLatestReviews();
	blockMapPlaces();
	anchormenu();
	blockCollapse();
	blockSliderPagination(Swiper, Autoplay, Navigation, Pagination);

	// Prevent empty links
	const links = document.querySelectorAll('a[href="#"]');
	links.forEach((el) => {
		const link = el;
		link.onclick = (e) => {
			e.preventDefault();
		};
	});
});
